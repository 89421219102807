<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>学校收益</p>

      </div>
      <div class="headerBox">
        <div class="ClassInfo_box">
          <div class="chartTitle">

            <ul>
              <li v-for="(item, index) in dateList" :key="index" @click="Atpitch(index,item)"
                :class="currentIndex == index ? 'active' : ''" style="margin-top: 16px;">
                {{ item }}
              </li>

            </ul>
          </div>

        </div>

      </div>
      <el-table :data="bonus_list" border style="width: 96%; margin-top: 2px;">
        <el-table-column prop="SchoolName" label="学校名称"></el-table-column>
        <el-table-column prop="zsy" label="收益总额"></el-table-column>
        <el-table-column prop="ts_bonus" label="广告收益"></el-table-column>
        <el-table-column prop="ts_read" label="广告推送量"></el-table-column>
        <el-table-column prop="yyp_bonus" label="音视频收益"></el-table-column>
        <el-table-column prop="yyp_num" label="音视频购买人次"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="detail(scope.row)">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>


    </div>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currpage" :page-size="pagesize"
        background layout="total,prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import {
    agentProfitSchool
  } from '@/api/agentApi.js'

  export default {
    data() {
      return {
        bonus_list: [], // 学生列表
        datetype: 1,
        currpage: 1, // 初始页
        pagesize: 10, // 每页显示条目个数
        CountNum: 0, // 总条数
        isContinue: 0,
        dialogFormVisible: false, // 添加设备弹窗
        dateList: ['昨天', '近3天', '近7天', '近30天'], // 选项
        currentIndex: 0, //天数默认
      }
    },
    created() {
      if (localStorage.getItem('agentProfitSchool') != null) {
        this.currpage = parseInt(localStorage.getItem('currpage'));
        this.datetype = parseInt(localStorage.getItem('datetype'));
        this.currentIndex = parseInt(localStorage.getItem('datetype')) - 1;
        localStorage.setItem('agentProfitSchool', null)
      }
    },
    mounted() {

      this.schoolList(this.currpage); // 查询省市区
    },
    methods: {
      schoolList(currpage) { //查询所有学生
        const data = {
          limit: this.pagesize,
          page: this.currpage,
          datetype: this.datetype
        }
        this.loading = true
        agentProfitSchool(data).then(res => {
          console.log('agentProfitSchool', res.data)
          this.CountNum = parseInt(res.data.countNum) // 总条数
          this.bonus_list = res.data.list;
          this.isContinue = res.data.isContinue
          //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
          this.currpage = currpage


          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },

      // schoolListListSearchHandle(currpage) { // 带有查询时分页 当有条件查询 分页时走这个方法
      // 	this.loading = true

      // 	const data = {
      // 		page: currpage, // 第一次查询时候默认1
      // 		limit: this.pagesize, // 总数
      // 		datetype: this.datetype
      // 	}

      // 	agentProfitSchool(data).then(res => {
      // 		this.bonus_list = res.data.list // 已审核用户列表
      // 		this.CountNum = parseInt(res.data.CountNum) // 总条数
      // 		this.loading = false
      // 		//在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
      // 		this.currpage = currpage;
      // 		this.isContinue = res.data.isContinue
      // 	}).catch(err => {
      // 		console.log(err)
      // 	})
      // },
      handleCurrentChange(val) { // 分页

        this.currpage = val
        this.schoolList(val)

      },
      Atpitch(index, item) { // 折线图时间选择索引
        this.currentIndex = index

        switch (index) {
          case 0:
            this.datetype = 1;
            this.healthHistory(1);
            break
          case 1:
            this.datetype = 2;
            this.healthHistory(2);
            break
          case 2:
            this.datetype = 3;
            this.healthHistory(3);
            break
          case 3:
            this.datetype = 4;
            this.healthHistory(4);
            break
          default:
            this.datetype = 1;
            this.healthHistory(1)
        }
      },
      healthHistory(datetype) {
        this.loading = true

        const data = {
          page: this.currpage, // 第一次查询时候默认1
          limit: this.pagesize, // 总数
          datetype: datetype
        }

        agentProfitSchool(data).then(res => {
          console.log('res', res);
          this.bonus_list = res.data.list // 已审核用户列表
          this.CountNum = parseInt(res.data.countNum) // 总条数
          this.loading = false
          //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
          this.currpage = this.currpage;
          this.isContinue = res.data.isContinue
        }).catch(err => {
          console.log(err)
        })
      },
      detail(row) { // 点击详情
        localStorage.setItem('Route', 'agentProfitSchool') // 存跳转标识
        localStorage.setItem('agentProfitSchool', this.currpage) // 存跳转页
        localStorage.setItem('currpage', this.currpage) // 存跳转页面面
        localStorage.setItem('datetype', this.datetype) // 存跳转页面
        this.$router.push({
          name: 'school_details',
          params: {
            schoolid: row.schoolid,
            datetype: this.datetype,
            SchoolName: row.SchoolName
          }
        })
      },
    }
  }

</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    // background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }

    .ClassInfo_box {
      width: 96%;
      margin: 0 auto;
      // background: white;

      .chartTitle {
        height: 51px;
        // display: flex;
        align-items: center;

        // justify-content: space-between;
        //   border-bottom:1px solid #cccccc ;
        .head_dates {
          display: inherit;
          margin-left: 102px;
          font-weight: bold;
        }

        .facility {
          font-size: 16px;
          line-height: 24px;
          color: #1890FF;
          margin-left: 32px;
          font-family: PingFangSC-Medium;
        }

        ul {
          padding: 0;
          margin: 0;
          font-size: 14px;
          margin-left: 54px;

          li {
            float: left;
            list-style: none;
            padding-left: 33px;
            border-radius: 25px;
            // background: #73AD21;
            padding: 0px;
            width: 80px;
            line-height: 30px;
            font-size: 15px;
          }

          li:hover {
            cursor: pointer,

          }
        }
      }
    }

    .active {
      //检测人滑动点击css
      color: #ff000080;
      font-size: 14px;
      background: #1890FF;
    }
  }

</style>
